import styles from './styles.module.scss'
import cx from "classnames";
import logo from '../images/logo.png'
import React from 'react';

import {CUSTOMER_KEY, INTEGRATION} from "../../constants/constants";
import HelpIcon from '@mui/icons-material/Help';
import ChatIcon from '@mui/icons-material/Chat';
import LinkIcon from "@mui/icons-material/Link";
import TextArea from "antd/es/input/TextArea";
import LiveTvIcon from '@mui/icons-material/LiveTv';
import LinkOffIcon from '@mui/icons-material/LinkOff';

const Header = (props) => {

    const getSupportLinkBySystem = () => {
        const contract = props?.contract;
        const customerId = sessionStorage.getItem(CUSTOMER_KEY) || localStorage.getItem(CUSTOMER_KEY)
        const integration = sessionStorage.getItem(INTEGRATION) || localStorage.getItem(INTEGRATION)
        //console.log("getSupportLinkBySystem Header")

        if (contract === undefined) {
            window.open("mailto:support@sepaheld.de?subject=Support%20Anfrage%20Registierung%20customerId%20"+customerId, "_blank");
        }
        if (contract?.contractType === "STRIPE") {
            window.open("mailto:support@sepaheld.de?subject=Support%20Anfrage%20Sepaheld+STR%20customerId%20"+customerId+"%20integration%20"+integration, "_blank");
        }

        return "mailto:support@sepaheld.de?subject=Support%20Anfrage%20Sepaheld+Lexoffice%20customerId%20"+customerId;
    }

  return (
      <div className={cx([styles.navbar, "d-flex", "justify-content-between"])}>

        <div className={cx(
            ["d-flex", "p-lg-4", "p-md-4", "p-sm-4", "pb-4", "pr-0"])}>
          <img src={logo} alt="logo" style={{height: '53px'}}/>
        </div>
        <div className={cx(styles.rightHeader, "d-flex", "align-items-center",
            "justify-content-between", "p-lg-4", "p-md-4", "p-sm-4", "pb-4",
            "px-0")}>
          <button>
            <a className={cx(["mx-0"])}
               href="https://sepaheld.com/f_a_q"
               rel="noreferrer"
               target="_blank">
                <HelpIcon size={20} style={{ color: 'white' }} />
            </a>
          </button>
          <button>
            <a className={cx(["mx-0"])}
               href="https://sepaheld.com/f_a_q"
               rel="noreferrer"
               target="_blank">
                <LiveTvIcon size={20} style={{ color: 'white' }} />
            </a>
          </button>
          <button onClick={() => getSupportLinkBySystem()}>
              <ChatIcon size={20} style={{ color: 'white' }} />
          </button>
          <span
              className={"text-white mx-2 d-flex align-items-center"}>&nbsp;</span>
        </div>
      </div>
  )

}

export default Header;
