export const OnboardingStageEnum = {
  ConnectLexoffice: 'Connect Lexoffice',
  ConnectGoCardless: 'Connect GoCardless',
  PaymentPlan: 'Payment Plan',
  BillingDetails: 'Billing Details',
  PaymentMethod: 'Payment Method',
  ConfirmOrder: 'Confirm Order'
};

export const StatusErrorsEnum = {
  NotConnected: 'NOT_CONNECTED',
  LexofficeNotConnected: 'LEXOFFICE_NOT_CONNECTED',
  GoCardlessNotConnected: 'GOCARDLESS_NOT_CONNECTED',
  PaymentPlanNotSelected: 'PAYMENT_PLAN_NOT_SELECTED',
  BillingDetailsMissing: 'BILLING_DETAILS_MISSING',
  PaymentMethodNotConfigured: 'PAYMENT_METHOD_NOT_CONFIGURED',
  LastPaymentFailed: 'LAST_PAYMENT_FAILED',
  GoCardlessNotFullVerified: 'ACCOUNT_NOT_FULLY_VERIFIED',
  GoCardlessAccountInReview: 'ACCOUNT_IN_REVIEW'
}