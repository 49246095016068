import {get, post} from './Requester';
import {getCustomerEndpoint, postCustomerEndpoint} from './CustomerRequester';
import {customerId as getCustomerId} from "./services/LoginService";

export function createUserAndCustomer(customer) {
    return new Promise((resolve, reject) => {
        post('register/customer', customer)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    })
}

export function checkCityAndCompany(city, company) {
    const encodedCity = encodeURI(city);
    const encodedCompany = encodeURI(company);
    return new Promise((resolve, reject) => {
        get(`register/check-company?city=${encodedCity}&name=${encodedCompany}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    })
}

export function postInvoices(){
    return new Promise((resolve, reject) => {
    postCustomerEndpoint('/invoices')
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            reject(error);
        })
    })
    }

export function getStatisticData(){
    return new Promise((resolve, reject) => {
        getCustomerEndpoint('statistics', undefined)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error);
            })
    })
}

export function getCustomerOnboardingStatus() {
    return getCustomerEndpoint('status').then(response => {
        return response.data
    })
}

export function getCustomerIntegrationMatching() {
    return getCustomerEndpoint('status/matching').then(response => {
        return response.data
    })
}

export function getCustomerPortal(){
    const customerId = getCustomerId();

    return get('stripe/' + customerId + '/customer-billing-portal').then(response => {
        return response.data;
    });
}

export function getCustomerPortalForUpgrade(){
    const customerId = getCustomerId();

    return get('stripe/' + customerId + '/upgrade/customer-billing-portal').then(response => {
        return response.data;
    });
}

export function upgradeFromLexofficeToStripe(premium=false){
    const customerId = getCustomerId();

    return get('stripe/' + customerId + '/upgrade?premium='+premium).then(response => {
        return response.data;
    });
}

export function updateCollectPaymentsDaysEarlier(days){
    return postCustomerEndpoint('/collectPaymentsDaysEarlier', {daysEarlier: days}).then(response => {
        return response.data;
    });
}

export function updatePaymentDescription(paymentDescription){
    return postCustomerEndpoint('/paymentDescription', {"paymentDescription": paymentDescription}).then(response => {
        return response.data;
    });
}
