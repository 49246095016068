// const CURRENT_BASE_URL = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
//const CURRENT_BASE_URL = "https://dev.backend.sepaheld.com/api"
const CURRENT_BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : "https://dev.backend.sepaheld.com/api";

const configurations = {
    development: {
        registerPricingTableId: 'prctbl_1OTrdIBfaexXG5Y7OSyiJygx',
        registerPublishableKey: 'pk_test_51I1AkuBfaexXG5Y712F1vcprZJfc9MBRoyaCnrAApOyje68ql1UCo1lCaQ4wK3s8gwOdkwgo89HPS5YeADWSoAJ700DtlBIpv7',
        upgradePremiumPricingTableId: 'prctbl_1P3yL5BfaexXG5Y7841KGLvJ',
        upgradePremiumPublishableKey: 'pk_test_51I1AkuBfaexXG5Y712F1vcprZJfc9MBRoyaCnrAApOyje68ql1UCo1lCaQ4wK3s8gwOdkwgo89HPS5YeADWSoAJ700DtlBIpv7',
        discountCodeAnnuallyStandard: '4NxfGNOd',
        discountCodeUpgradePremium: 'SEPAHELD2024',
        gocardlessVerificationUrl: 'https://verify-sandbox.gocardless.com'
    },
    production: {
        registerPricingTableId: 'prctbl_1PIrIQBfaexXG5Y7x2C43tUJ',
        registerPublishableKey: 'pk_live_51I1AkuBfaexXG5Y7NHExTiVDno6nW5JuTEBvsl1MuIyW809rbVio4jGqo2PokmXpkxLmbi5yb4FEQSvogHFbL1P7007X1FBSBQ',
        upgradePremiumPricingTableId: 'prctbl_1PIrVmBfaexXG5Y7yXWi9ktO',
        upgradePremiumPublishableKey: 'pk_live_51I1AkuBfaexXG5Y7NHExTiVDno6nW5JuTEBvsl1MuIyW809rbVio4jGqo2PokmXpkxLmbi5yb4FEQSvogHFbL1P7007X1FBSBQ',
        discountCodeAnnuallyStandard: 'TJOAKNUM',
        discountCodeUpgradePremium: 'SEPAHELD2024',
        gocardlessVerificationUrl: 'https://verify.gocardless.com'
    },
};

const env = process.env.NODE_ENV ? process.env.NODE_ENV : 'development';

const configToUse = CURRENT_BASE_URL.includes('dev.backend.sepaheld.com') ? 'development' : 'production';

const config = {
    API_BASE_URL: CURRENT_BASE_URL + '/',
    env,
    params: configurations[configToUse]
};

export function resolveApiUrl(apiResourcePath) {
    if (apiResourcePath.startsWith('/')) {
        apiResourcePath = apiResourcePath.substring(1);
    }
    return config.API_BASE_URL + apiResourcePath;
}

export default config;
