import { useEffect, useState } from "react";
import { getCustomerOnboardingStatus } from "../../js/CustomerService";
import { OnboardingStageEnum as OnboardingStage, StatusErrorsEnum as StatusErrors } from "../../constants/Enums";
import { getCustomerContractPlan } from "../../js/ContractRequester";

const _statusErrors = (data) => {
  const errors = [];
  if (data?.lexOfficeErrors?.indexOf(StatusErrors.NotConnected) >= 0) {
    errors.push(StatusErrors.LexofficeNotConnected);
  }
  if (data?.goCardlessErrors?.indexOf(StatusErrors.NotConnected) >= 0) {
    errors.push(StatusErrors.GoCardlessNotConnected);
  }
  if (data?.sepaHeroErrors?.indexOf(StatusErrors.PaymentPlanNotSelected) >= 0) {
    errors.push(StatusErrors.PaymentPlanNotSelected);
  }
  if (data?.sepaHeroErrors?.indexOf(StatusErrors.BillingDetailsMissing) >= 0) {
    errors.push(StatusErrors.BillingDetailsMissing);
  }
  if (data?.sepaHeroErrors?.indexOf(StatusErrors.PaymentMethodNotConfigured) >= 0) {
    errors.push(StatusErrors.PaymentMethodNotConfigured);
  }
  if (data?.sepaHeroErrors?.indexOf(StatusErrors.LastPaymentFailed) >= 0) {
    errors.push(StatusErrors.LastPaymentFailed);
  }
  return errors;
}

const _stage = (error) => {
  switch (error) {
    case StatusErrors.LexofficeNotConnected:
      return OnboardingStage.ConnectLexoffice;
    case StatusErrors.GoCardlessNotConnected:
      return OnboardingStage.ConnectGoCardless;
    case StatusErrors.PaymentPlanNotSelected:
      return OnboardingStage.PaymentPlan;
    case StatusErrors.BillingDetailsMissing:
      return OnboardingStage.BillingDetails;
    case StatusErrors.PaymentMethodNotConfigured:
      return OnboardingStage.PaymentMethod;
    case StatusErrors.LastPaymentFailed:
      return OnboardingStage.ConfirmOrder;
    default:
      return undefined;
  }
}

export const useOnboardHooks = () => {

  const [onboardStatus, setOnboardStatus] = useState({ resetOnboardSelectedState: 0, statusErrors: [] });
  const { resetOnboardSelectedState, statusErrors, customerContractPlans } = onboardStatus;
  const [gocardlessStatus, setGocardlessStatus] = useState();

  /**
   * @param {{resetOnboardSelectedState?:boolean}} [options]
   */
  const loadStatus = async (options) => {
    try {
      const data = await getCustomerOnboardingStatus();

      //set GoCardless status
      if (data?.goCardlessErrors?.indexOf(StatusErrors.GoCardlessAccountInReview) >= 0) {
        setGocardlessStatus(StatusErrors.GoCardlessAccountInReview);
      }else if (data?.goCardlessErrors?.indexOf(StatusErrors.GoCardlessNotFullVerified) >= 0) {
        setGocardlessStatus(StatusErrors.GoCardlessNotFullVerified);
      }

      const newStatusErrors = _statusErrors(data);
      // TODO decide whether we should wait second call to finish before to "trigger" stare change
      // TODO decide whether we should ignore possible errors and "trigger" stare change only with result from onboarding status request
      const newCustomerContractPlans = await getCustomerContractPlan();
      // TODO set new state only in case there is a real change
      setOnboardStatus(previous => ({
        resetOnboardSelectedState: options?.resetOnboardSelectedState ? Date.now() : previous.resetOnboardSelectedState,
        statusErrors: newStatusErrors,
        customerContractPlans: newCustomerContractPlans
      }));
    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    loadStatus();
  }, []);

  return {
    isOnboarded: statusErrors.length > 0 ? false : true,
    onboardErrors: statusErrors,
    loadStatus,
    lastOnboardingStep: _stage(statusErrors[0]),
    customerContractPlans,
    resetOnboardSelectedState,
    gocardlessStatus
  };
}

