import React, {Suspense, useEffect} from "react"
import {injectIntl} from "react-intl";
import {Route, Routes, useNavigate} from 'react-router-dom';
import {useOnboardHooks} from "./hooks/MainApp/hooks";

const MainApp = ({history}) => {

    const MainAppIndex = React.lazy(() => import("./MainAppIndex"));
    const ProfilePageIndex = React.lazy(
        () => import ( "./components/Profile/ProfilePageIndex"));
    const Onboard2 = React.lazy(() => import ( "./components/Onboard/Onboard2"));

    const {
        isOnboarded,
        onboardErrors,
        loadStatus,
        lastOnboardingStep,
        resetOnboardSelectedState,
        gocardlessStatus
    } = useOnboardHooks();

    const navigate = useNavigate();

    useEffect(() => {
        if (!isOnboarded && onboardErrors) {
            navigate("/app/onboard");
        }
    }, [isOnboarded, onboardErrors, navigate])

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route exact path="/" element={
                    <MainAppIndex
                        gocardlessStatus={gocardlessStatus}
                        loadStatus={loadStatus}
                    />
                }
                />
                <Route exact path="/onboard" element={
                    <Onboard2
                        isOnboarded={isOnboarded}
                        onboardErrors={onboardErrors}
                        loadStatus={loadStatus}
                        lastOnboardingStep={lastOnboardingStep}
                        resetOnboardSelectedState={resetOnboardSelectedState}
                        history={history}
                    />}/>
                <Route exact path="/profile" element={<ProfilePageIndex/>}/>
            </Routes>
        </Suspense>
    )
}

export default injectIntl(MainApp);
